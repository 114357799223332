<template>
    <div>
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem>
                <router-link :to="{ name: 'ProjectTasks' }">Task</router-link>
            </CBreadcrumbItem>

            <CBreadcrumbItem active>{{ taskName }}</CBreadcrumbItem>
        </teleport>

        <router-view/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'Index',
    inject: ['mountedComponent'],
    computed: {
        ...mapGetters({
            taskName: 'taskName',
        }),
    },
    created() {
        this.setTask({})
    },
    methods: {
        ...mapActions(['setTask'])
    },
}
</script>
